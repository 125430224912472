import React, { useState, useRef } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [file, setFile] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [quote, setQuote] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [complexity, setComplexity] = useState(1);
  const [urgency, setUrgency] = useState('normal');
  const [useCase, setUseCase] = useState('web');

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    
    // Simula alcune informazioni sul file
    setFileInfo({
      name: uploadedFile.name,
      size: uploadedFile.size,
      type: uploadedFile.type,
    });

    // Crea un'anteprima per file SVG
    if (uploadedFile.type === "image/svg+xml") {
      const reader = new FileReader();
      reader.onload = (e) => setPreview(e.target.result);
      reader.readAsDataURL(uploadedFile);
    } else {
      setPreview(null);
    }
  };

  const generateQuote = () => {
    const basePrices = {
      'image/svg+xml': 10,  // SVG
      'application/postscript': 15,  // AI
      'application/eps': 13,  // EPS
    };
    const complexity = Math.random() * 2 + 1;
    const elements = Math.floor(Math.random() * 100) + 1;
    let basePrice = basePrices[fileInfo.type] || 100;
    let finalPrice = basePrice * complexity + (elements * 0.5);
    finalPrice = Math.round(finalPrice * 100) / 100;
    // const randomQuote = Math.floor(Math.random() * 1000) + 500;
    setQuote(finalPrice);
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Preventivo Grafica Vettoriale</h1>
      <div className="file-upload-wrapper mb-3">
        <input
          type="file"
          className="file-upload-input"
          onChange={handleFileUpload}
          accept=".svg,.ai,.eps"
          ref={fileInputRef}
        />
        <label className="file-upload-label" onClick={() => fileInputRef.current.click()}>
          Seleziona o trascina qui il tuo file
        </label>
      </div>
      {fileInfo && (
        <div className="card file-info-card mb-3">
          <div className="card-body">
            <h5 className="card-title">Informazioni File</h5>
            <p>Nome: {fileInfo.name}</p>
            <p>Dimensione: {fileInfo.size} bytes</p>
            <p>Tipo: {fileInfo.type}</p>
          </div>
        </div>
      )}
      {preview && (
        <div className="preview-container mb-3">
          <h5>Anteprima</h5>
          <img src={preview} alt="Anteprima SVG" className="preview-image" />
        </div>
      )}

      <div className="form-group">
        <label>Complessità:</label>
        <input 
          type="range" 
          min="1" 
          max="5" 
          value={complexity} 
          onChange={(e) => setComplexity(e.target.value)}
          className="form-control-range"
        />
      </div>

      <div className="form-group">
        <label>Urgenza:</label>
        <select 
          value={urgency} 
          onChange={(e) => setUrgency(e.target.value)}
          className="form-control"
        >
          <option value="low">Bassa</option>
          <option value="normal">Normale</option>
          <option value="high">Alta</option>
        </select>
      </div>

      <div className="form-group">
        <label>Uso previsto:</label>
        <select 
          value={useCase} 
          onChange={(e) => setUseCase(e.target.value)}
          className="form-control"
        >
          <option value="web">Web</option>
          <option value="print">Stampa</option>
          <option value="both">Entrambi</option>
        </select>
      </div>

      <button className="btn btn-primary generate-quote-btn" onClick={generateQuote} disabled={!fileInfo}>
        Genera Preventivo
      </button>
      {quote && (
        <div className="alert alert-success mt-3 quote-result">
          Il preventivo stimato è: €{quote}
        </div>
      )}
    </div>
  );
}

export default App;